import React from 'react';

function Roadmap() {
  return (
    <section className="roadmap" id="roadmap">
      <h2>Roadmap</h2>
      <div className="roadmap-phases">
        {/* Phase 1: Whitelist Stage */}
        <div className="phase">
          <div className="phase-number">1</div>
          <h3>Whitelist Stage</h3>
          <p>
            <strong>Whitelist Registration:</strong> Open registration for early access to the NFT collection. Collect user information for the whitelist.
          </p>
          <p>
            {/* <strong>Community Engagement:</strong> Engage with the community to build anticipation and hype for the upcoming minting stage. */}
          </p>
          <p>
            {/* <strong>Exclusive Benefits:</strong> Offer exclusive benefits to whitelist participants, such as early minting access, limited edition rewards, and priority participation in future events. */}
          </p>
          <p>
               Start:14 May End:11 June
          </p>
        </div>

        {/* Phase 2: Minting Stage */}
        <div className="phase">
          <div className="phase-number">2</div>
          <h3>Minting Stage</h3>
          <p>
            <strong>NFT Minting:</strong> Launch the Looky Friends NFT collection, featuring unique dreams. Mint a limited number of these NFTs during this phase.
          </p>
          <p>
            {/* <strong>Exclusive Benefits:</strong> Early adopters receive exclusive benefits, including limited edition physical merchandise, and priority access to future phases. */}
          </p>
          <p>Whitelist:13 June- 6PM UTC+3</p> <p>Public:14 June- 6AM UTC+3</p>
        </div>

        {/* Phase 3: Revealing Stage */}
        <div className="phase">
          <div className="phase-number">3</div>
          <h3>Revealing Stage</h3>
          <p>
            <strong>NFT Reveal:</strong> Officially reveal the Looky Friends NFT collection to the public. Showcase the unique features and characteristics of each NFT.
          </p>
          <p>
            {/* <strong>Interactive Events:</strong> Host interactive events such as live streams, AMA sessions, and virtual gallery exhibitions to celebrate the collection's launch. */}
          </p>
          <p>
            {/* <strong>Secondary Market Integration:</strong> Integrate with secondary marketplaces to enable trading and resale of Looky Friends NFTs. */}
          </p>
          <p>Reveal:17 June</p>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
