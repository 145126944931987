import React, { useState } from 'react';
import '../App.css'; // Create a separate CSS file for styling the FAQ section


function FAQ() {
  const faqItems = [
    {
      question: 'Q:What is the Looky Friends NFT collection?',
      answer: 'A:The "Looky Friends" NFT collection is a unique and limited set of 10,000 digital art pieces, each represented as a non-fungible token (NFT)'
    },
    {
      question: 'Q:How many unique pieces are there in the "Looky Friends" collection?',
      answer: 'A:The collection comprises 10,000 unique pixel art pieces, created from a combination of approximately 200 layers or attributes'
    },
    {
      question: 'Q:What is the rarity of the different Looky Friends NFTs?',
      answer: 'A:The rarity of Looky Friends NFTs is determined by the combination of their traits. Some traits may be more rare than others, adding to the uniqueness of each NFT'
    },
    {
      question: 'Q:What is the image resolution of each Looky Friends?',
      answer: 'A:Each image is a 2000 x 2000px PNG'
    },
    {
      question: 'Q:Which Blockchain is the Looky Friends NFT created on?',
      answer: 'A:Our Looky Friends NFTs are created on the Base blockchain using a ERC-721 Contract'
    },
    {
      question: 'Q:How can I purchase a Looky Friends NFT?',
      answer: 'A:You can purchase Looky Friends NFTs directly from our website using popular cryptocurrencies and compatible wallets. Detailed instructions are provided on our website for the purchasing process'
    },
    {
      question: 'Q:Are there any future plans or updates for the Looky Friends project?',
      answer: 'A:Yes, we have exciting plans and updates in store for the "Looky Friends" project. Stay tuned to our announcements for information on upcoming developments and events'
    },
    {
      question: 'Q:What are the 10 different categories within the "Looky Friends" collection?',
      answer: 'A:The categories include human, ape, cat, bird, alien, skeleton, ghost, robot, devil, and frog, with the majority being human'
    },
    {
      question: 'Q:Can I resell my Looky Friends NFTs on secondary marketplaces?',
      answer: 'A:Yes, you can resell your Looky Friends NFTs on supported secondary marketplaces. Many NFT collectors actively engage in trading and selling their digital assets'
    },
    {
      question: 'Q:Are there any benefits for early buyers of Looky Friends NFTs?',
      answer: 'A:Early buyers may receive special benefits, such as access to exclusive events, airdrops, or community privileges. Be sure to stay updated with our announcements for any early-bird incentives'
    },
    {
      question: 'Q:When is the sales event for the "Looky Friends" NFT collection, and what are the pricing details?',
      answer: 'A:The sales event is scheduled for June 13, 2024, at 6PM UTC+3. There will be two formats: a whitelist sale priced at 0.005 Ethereum each and a public sale priced at 0.008 Ethereum each. The sale will take place on lookyfriends.xyz'
    },
    {
      question: 'Q:Is there a limit to the number of NFTs one can purchase during the sales event?',
      answer: 'A:Yes, there is a limit to the number of NFTs one can purchase, depending on whether you participate in the whitelist or public sale'
    },
    // {
    //   question: 'Q:Are there any benefits for early buyers of Looky Friends NFTs?',
    //   answer: 'A:Early buyers may receive special benefits, such as access to exclusive events, airdrops, or community privileges. Be sure to stay updated with our announcements for any early-bird incentives'
    // },
    {
      question: 'Q:What is the resale royalty for "Looky Friends" NFTs?',
      answer: 'A:There is a 5% resale royalty fee on secondary market transactions of "Looky Friends" NFTs'
    },

    // Add more FAQ items as needed
  ];

  // Add an extra FAQ item
  const extraFaqItem = {
    question: 'Q:What support channels are available if I have questions or issues with my "Looky Friends" NFTs?',
    answer: 'A:For any inquiries or issues, please contact our customer support at (team@lookyfriends.xyz) or through the contact form on our website. Updates and announcements will also be shared on Twitter (@lookyfriends) and Discord'
  };

  const [openItemIndex, setOpenItemIndex] = useState(null);

  const toggleItem = (index) => {
    if (index === openItemIndex) {
      setOpenItemIndex(null);
    } else {
      setOpenItemIndex(index);
    }
  };

  return (
    <section id="faq">
      <div className="faq-container"> {/* Added container for FAQ */}
        <h2>FAQ</h2>
        <ul className="faq-list">
          {faqItems.map((item, index) => (
            <li
              key={index}
              className={`faq-item ${openItemIndex === index ? 'open' : ''}`}
            >
              <div className="faq-question" onClick={() => toggleItem(index)}>
                <h3>{item.question}</h3>
                <button className="toggle-button">
                  {openItemIndex === index ?  '▲':'▼' }
                </button>
              </div>
              {openItemIndex === index && (
                <div className="faq-answer" dangerouslySetInnerHTML={{ __html: item.answer }} />
              )}
            </li>
          ))}

          {/* Additional FAQ item */}
          <li
            className={`faq-item ${openItemIndex === faqItems.length ? 'open' : ''}`}
          >
            <div className="faq-question" onClick={() => toggleItem(faqItems.length)}>
              <h3>{extraFaqItem.question}</h3>
              <button className="toggle-button">
                {openItemIndex === faqItems.length ? '▲':'▼'}
              </button>
            </div>
            {openItemIndex === faqItems.length && (
              <div className="faq-answer" dangerouslySetInnerHTML={{ __html: extraFaqItem.answer }} />
            )}
          </li>
        </ul>
      </div>
    </section>
  );
}

export default FAQ;
