import React from 'react';
import image1 from '../images/image1.png';
import image2 from '../images/image2.png';
import image3 from '../images/image3.png';
import image4 from '../images/image4.png';
import image5 from '../images/image5.png';
import image6 from '../images/image6.png';
import image7 from '../images/image7.png';
import image8 from '../images/image8.png';
import image9 from '../images/image9.png';
import image10 from '../images/image10.png';

function NFTImage() {
  // Define an array of image variables
  const images = [image1, image2, image3, image4, image5, image6, image7, image8,image9,image10];

  return (
    <section id="nft-image" className="image-container">
      {images.map((image, index) => (
        <div className="image-item" key={index}>
          <img src={image} alt={`NFT ${index + 1}`} className="motion-image" />
        </div>
      ))}
    </section>
  );
}

export default NFTImage;
