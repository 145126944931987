import React from 'react';
import twitterLogo from '../images/twitter-logo.png'; // Replace with the path to your Twitter logo image
import openSeaLogo from '../images/opensea-logo.png'; // Replace with the path to your OpenSea logo image
import discordLogo from '../images/discord.png';

function Header() {
  
  
  return (
    <header className="header">
      <nav>
        <ul>
          <li><a href="#introduction">Home</a></li>
          <li><a href="#story">Story</a></li>
          {/* <li><a href="#nft-image">Collection</a></li> */}
          <li><a href="#roadmap">Roadmap</a></li>
          <li><a href="#faq">FAQ</a></li>
          {/* <li><a href="#faq">FAQ</a></li> */}
          <button className="mint-button">MINT</button>
        </ul>
      </nav>
      <div className="header-right">
        <a href="https://twitter.com/lookyfriends" target="_blank" rel="noopener noreferrer">
          <img src={twitterLogo} alt="Twitter Logo" className="social-logo" />
        </a>
        <a href="https://discord.gg/avXeetmaXh" target="_blank" rel="noopener noreferrer">
          <img src={discordLogo} alt="Discord Logo" className="social-logo" />
        </a>
        <a href="https://opensea.io/collection/" target="_blank" rel="noopener noreferrer">
          <img src={openSeaLogo} alt="OpenSeaLogo" className="social-logo" />
        </a>
      </div>
    </header>
  );
}



export default Header;

