import React from 'react';

function Story() {
  return (
    <section id="story">
      <h2>Our story</h2>
      <p className="brand-story">
      In the Realm of Unborn Dreams, "Looky Friends" emerged as ethereal entities shaped by the essence of
      unfulfilled aspirations. These curious beings, dubbed "Looky Friends," possessed a unique ability to
      understand and ignite people's deepest desires. Born from a realm filled with boundless hope, they
      ventured into the world, offering guidance and inspiration to dreamers in need. Their name, 
      "Looky Friends," signified their inquisitiveness and unwavering support.
      These whimsical beings served as conduits between the realm of dreams and the realm of reality,
      encouraging individuals to pursue their aspirations. Every encounter with a Looky Friend became a
      moment of serendipity, pushing people to believe in the transformative power of their dreams.
      In the end, Looky Friends will a living testament to the potential of turning dreams into reality
      through belief, action, and the unwavering support of those who believe in their dreams.
      </p>
    </section>
  );
}

export default Story;

