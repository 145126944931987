import React, { useState } from 'react';

const Mailbox = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSendEmail = () => {
    if (isValidEmail(email)) {
      // Send the email to the Mailchimp endpoint
      fetch('https://xyz.us18.list-manage.com/subscribe/post?u=3dcc0cbb3d1ead7bf66cb20a3&amp;id=e36484600e&amp;f_id=005d28e1f0', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `EMAIL=${encodeURIComponent(email)}`,
      })
        .then(() => {
          setMessage('Email subscription successful!');
        })
        .catch(() => {
          setMessage('Email subscription successful!');
        });
    } else {
      setMessage('Invalid email address. Please enter a valid email.');
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <div className="mailbox-container">
      <input
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="YOUR EMAIL ADDRESS"
        className="mailbox-input"
      />
      <button
        onClick={handleSendEmail}
        className="mailbox-button"
      >
        GET UPDATES
      </button>
      {message && <p className="mailbox-message">{message}</p>}
      <style jsx>{`
        .mailbox-container {
          position: relative;
        }
        .mailbox-message {
          position: absolute;
          bottom: -40px;
          left: 0;
          color: white;
          font-size: 17px;
        }
      `}</style>
    </div>
  );
};
export default Mailbox;
