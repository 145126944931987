import React from 'react';
import Mailbox from './Mailbox'; // Import the Mailbox component


function Introduction() {
  return (
    <section id="introduction" className="intro-container">
      <div className="intro-background"></div> {/* Background image container */}
      <div className="intro-content">
        <h1 className="intro-heading">LOOKY FRIENDS</h1>
        <p className="intro-paragraph">
          Welcome to the world of Looky Friends, where we bring you a delightful collection of 10,000 unique NFT and
          charming characters. Each one is crafted with love, personality, and charm, making them the perfect addition
          to your NFT collection
        </p>
        {/* Include the Mailbox component */}
        <Mailbox />
      </div>
    </section>
  );
}

export default Introduction;
