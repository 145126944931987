import React from 'react';
import './App.css'; // Import your CSS file
import Header from './components/Header';
import Introduction from './components/Introduction'; 
import Story from './components/Story';
import NFTImage from './components/NFTImage';
import Roadmap from './components/Roadmap';
import FAQ from './components/FAQ';
import twitterLogo from './images/twitter-logo.png'; 
import openSeaLogo from './images/opensea-logo.png';

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} LOOKY FRIENDS  All rights reserved.</p>
      <div className="footer-left">
        <a href="https://twitter.com/lookyfriends" target="_blank" rel="noopener noreferrer">
          <img src={twitterLogo} alt="Twitter Logo" className="social-logo" />
        </a>
        <a href="https://opensea.io/collection/yourcollection" target="_blank" rel="noopener noreferrer">
          <img src={openSeaLogo} alt="OpenSeaLogo" className="social-logo" />
        </a>
      </div>
    </footer>
  );
}

function App() {
  return (
    <div className="App">
      <Header />
      <Introduction /> 
      <Story />
      <NFTImage />
      <Roadmap />
      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
